/* (nb) navbar - 40px */
/* (rp) row padding (top/bottom) - 14px */
/* (sp) segment padding (top/bottom) - 14px */
/* (sb) segment border (top/bottom) - 1px */
/* (sm) margin between segments - 14px */

/* body {
  background-color: gainsboro;
} */

#mainTable {
  /* width: 98%; 
  margin-left: 1%; 
  margin-right: 1%;  */
  /* margin-top: 0; */
}

#scrollingTableWrapper {
  /* overflow: auto; */
  /* padding: 0; */
  /* margin-top: 1rem; */
}

#scrolling-table {
  /* margin-top: 0; */
}

/* #scrolling-table th:last-child,
#scrolling-table td:last-child {
  padding-right: 2rem;
} */

.main-table-search>input {
  border-radius: 500rem !important;
}

.ui.search>.results {
  height: 80vh;
  overflow: auto;
}

/* .table-button {
  padding-left: 2px !important;
  padding-right: 2px !important;
} */

.stats-table {
  /*                  nb     sb */
  /* height: calc(30vh - 40px - 2px); */
  /* height: 29.5rem; */
}

.sticky-header:first-child {
  top: 0px;
}

.sticky-header:not(:first-child) {
  top: 0px;
}

.sticky-header {
  position: sticky;
  /* top: 45px; */
  z-index: 4;
  /* background: white; */
  /* border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
  border-collapse: separate; */
  border-spacing: 0;
  white-space: nowrap;
  padding-top: 1rem;
}

/* #scrolling-table thead:first-child th {
  border-bottom: 0;
} */

/* #scrolling-table thead:not(:first-child) th {
  border-top: 1px solid rgba(34,36,38,.1);
} */

#pre-scrolling-table {
  height: 48px;
  width: 100%;
  z-index: 3;
  float: left;
  position: sticky;
  top: 0px;
  background: white;
}

#pre-scrolling-table-2 {
  height: 100%;
  width: 100%;
  /* z-index: 2;
  background: white; */
  border: 1px solid rgba(34, 36, 38, .15);
  border-radius: .28571429rem .28571429rem 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.stat-cell {
  font-weight: bold;
}

.graph-outer-level {
  height: calc(35rem+2px);
}

/* https://stackoverflow.com/questions/18359925/draw-an-arrow-inside-table-cell-using-css */
/* .external-ticker:after {
  content: "";
  display: block;
  position: relative;
  /* left: calc(100% - 0.5em); 
  /* use right: 0; instead 
  right: 0;
  top: 0;
  border-left: 0.5em solid transparent;
  border-top: 0.5em solid red;
} */

.dji::after {
  content: "";
  /* display: block; */
  position: absolute;
  /* float: right; */
  /* left: calc(100% - 0.5em); */
  /* use right: 0; instead */
  right: 0;
  top: 0;
  z-index: 1;
  border-left: 0.75em solid transparent;
  border-top: 0.75em solid green;
}

.m::after {
  content: "";
  /* display: block; */
  position: absolute;
  /* float: right; */
  /* left: calc(100% - 0.5em); */
  /* use right: 0; instead */
  right: 0;
  top: 0;
  z-index: 1;
  border-left: 0.75em solid transparent;
  border-top: 0.75em solid blue;
}

/* .headerCell-Ticker {
    border-top: 1px solid rgba(34,36,38,.15);
    border-top-left-radius: .28571429rem;
}

.headerCell-E20-12 {
  border-top: 1px solid rgba(34,36,38,.15);
  border-top-right-radius: .28571429rem;
} */

#leftMenuButton {
  position: absolute;
  top: -1.25rem;
  left: 0;
  z-index: 7;
  padding-top: 14px;
}

#rightMenuButton {
  position: absolute;
  top: -1.25rem;
  right: 0;
  z-index: 7;
}

#statsTable .ui.header {
  margin: 0.5rem 0 0.5rem 0;
}

#statsTable .row,
#statsTable .column,
#statsTable .grid,
#statsTable .grid.row,
#statsTable .grid.column,
#statsTable table {
  height: 100%;
}

#scrollingTableWrapper th[header],
#scrollingTableWrapper th i {
  cursor: pointer;
}

.chart-button {
  cursor: pointer;
}

.subcheckbox {
  padding-left: 26px;
}

#statsTable .statsButton {
  padding: .5em 1em;
}

.k-line-chart-container {
  display: flex;
  flex-direction: column;
  /* margin: 15px; */
  /* border-radius: 2px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, .3); */
  /* background-color: #1f2126; */
  /* width: 620px;
  height: 440px; */
  padding: 1rem 1rem .71rem 1rem;
}

.k-line-chart-title {
  margin: 0;
  color: #E6E8EA;
  padding-bottom: 10px;
}

.k-line-chart-top-menu-container {
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* margin-top: 1px; */
  /* font-size: 12px; */
  /* color: #929AA5; */
  /* float: left; */
}

.k-line-chart-bottom-menu-container {
  /* float: right; */
  margin-top: 6px;
}

/* .k-line-chart-menu-container button {
  cursor: pointer;
  background-color: #2196F3;
  border-radius: 2px;
  margin-right: 8px;
  height: 24px;
  line-height: 26px;
  padding: 0 6px;
  font-size: 12px;
  color: #fff;
  border: none;
  outline: none;
} */

/* .k-line-chart-splits-divs {
  display: flex;
  flex-direction: row;
  align-items: center;
} */

/* #tools-list .tools-list-row-icon-wrapper {
  width: 22px;
  height: 22px;
} */

.tools-list-row-icon {
  /* width: 22px; */
  /* height: 22px; */
  fill: #929aa5;
}

.color-picker-box {
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: center; */
  box-sizing: border-box;
  outline: none;
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  vertical-align: text-bottom;
}

.short-input {
  width: 60px;
}

.short-input input {
  width: 100%;
}

#chartSettingsTable td {
  border: 0;
}

#companyDescription {
  height: 100%;
  overflow: auto;
}

/* .tools-list:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tools-list:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tools-list:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
} */