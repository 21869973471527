html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

p {
  text-align: justify;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* 
#mainMenu .item {
  color: #000000;
}

#mainMenu .active.item {
  color: #000000 !important;
  border-color: #000000;
}

#mainMenu .ui.inverted.button {
  color: #000000;
  box-shadow: 0 0 0 2px #000000 inset;
}
*/

#getStarted {
  margin-top: 3rem;
  /* color: #000000; */
}

/*
#getStarted:hover {
  color: #000000;
}

#callToAction {
  color: #000000;
}

#mainMenu .ui.inverted.button:hover {
  background: #33a0ff;
}

#mainMenu .ui.inverted.button.active {
  background: #33a0ff;
} 
*/

#footer a {
  color: lightgray;
}

#footer h4 {
  color: white;
}

#footer p {
  color: white;
}

.ui.styled.accordion, .accordion {
  background: unset !important;
}

#learn-more .chakra-heading:after {
  content: '';
  width: 40px;
  height: 2px;
  background-color: #08f;
  position: absolute;
  margin-top: 29px;
  left: 1.3rem;
  border-radius: 4px;
}

.inverted .card .content .header:after {
  background-color: #21b8ff;
}

.underlined-header:after {
  content: '';
  width: 40px;
  height: 2px;
  background-color: #08f;
  position: absolute;
  margin-top: 40px;
  left: 0rem;
  border-radius: 4px;
}

.inverted.underlined-header:after {
  background-color: #21b8ff;
}

#learn-more-info .learn-more-title {
  position: relative;
  margin-bottom: 2rem;
}

.learn-more-title::after {
  content: '';
  height: 2px;
  width: 128px;
  color: #08f;
  border: solid 2px #08f;
  position: absolute;
  border-radius: 4px;
  bottom: -13px;
  left: 0;
}

.inverted.learn-more-title:after {
  color: #21b8ff;
  border: solid 2px #21b8ff;
}

#learn-more .learn-more-active:after {
  content: "";
  border: 1px solid #e2e8f0;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 1px 0 0 1px;
  display: inline-block;
  transform: rotate(-315deg);
  -webkit-transform: rotate(-315deg);
  position: absolute;
  bottom: -42px;
  background: #edf2f7;
  z-index: 1;
  width: 27px;
  height: 27px;
  left: calc(50% - 13.5px);
  visibility: visible;
}

.chakra-ui-dark #learn-more .learn-more-active:after {
  background: #2d3748;
  border-color: rgba(255, 255, 255, 0.16);
}

.bottom-fade {
  width: 100%;
  height: 200px;
  z-index: 99;
  position: absolute;
  bottom: -50px;
  background: url("/bottom-fade.png") bottom center no-repeat;
}

ol {
  counter-reset: section;                /* Creates a new instance of the
                                            section counter with each ol
                                            element */
  list-style-type: none !important;
}

ol li::before {
  counter-increment: section;            /* Increments only this instance
                                            of the section counter */
  content: counters(section, ".") ".\A\2003";   /* Combines the values of all instances
                                            of the section counter, separated
                                            by a period */
  
}

.largeButtonIcons {
  width: 26px;
  height: 26px;
}

.tabBreak {
  display: inline-block;
  min-width: 5rem;
}